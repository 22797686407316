@charset "utf-8";
html,
body {
  height: 100%;
}
body {
  margin: 0px;
  padding: 0px;
  color: #776955;
  font-size: 16px;
  line-height: 2;
  background: #fff;
  -webkit-text-size-adjust: none;
}
ul {
  list-style-type: none;
}
ol {
  padding-left: 40px;
  padding-bottom: 15px;
}
img {
  border: none;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
  font-size: 100%;
  border-spacing: 0;
}
iframe {
  width: 100%;
}

@keyframes logoFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes menubarFadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

a {
  color: #776955;
}

header {
  height: 100vh;
  overflow: auto;
  position: relative;
  background: #f9fbfa;
}

header #logo img {
  display: block;
  width: 20%;
  position: absolute;
  left: 40%;
  bottom: 35%;

  animation-name: logoFadeIn;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

#menubar {
  position: absolute;
  bottom: 15%;
  width: 100%;
  text-align: center;
}

#menubar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center; /* メニューアイテムを中央に寄せます */
  flex-wrap: wrap;
}

#menubar li {
  margin: 0 50px;
  animation: menubarFadeInUp 0.5s ease-out forwards;
}
#menubar li a {
  display: block;
  padding: 10px 0;
  text-decoration: none;
  color: #3429c5;
  transition: color 0.3s ease;
}

#menubar li a:hover {
  color: #3429c5;
  text-decoration: underline;
}

#container {
  max-width: 1400px; /*最大幅。これ以上広くならない指定。*/
  margin: 0 auto;
  background: #f9fbfa;
}

#contents {
  overflow: hidden;
  padding: 50px 3%;
}
#contents section + section {
  padding-top: 50px;
}
#contents h2 {
  margin-bottom: 20px;
  clear: both;
  padding: 0 20px;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  background: #3429c5;
  color: #fff;
  border-radius: 100px;
}

#contents h3 {
  margin-bottom: 20px;
  clear: both;
  padding: 0 20px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.1em;
  border: 2px solid #3429c5;
  border-radius: 100px;
  background: #fff;
}

#contents p {
  padding: 0px 20px 20px;
}

#contents p + p {
  margin-top: -5px;
}
#contents h2 + p,
#contents h3 + p {
  margin-top: -10px;
}

.list {
  overflow: hidden;
  line-height: 1.5;
  border-bottom: 1px solid #4f76ff;
  padding: 20px;
}
.list h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.list img {
  width: 20%;
  float: left;
  margin-right: 3%;
}

.list p {
  padding: 0 !important;
}

footer {
  clear: both;
  text-align: center;
  background: #3429c5;
  color: #fff;
  font-size: 85%;
}
footer a {
  color: #fff;
  text-decoration: none;
}
footer a:hover {
  color: #fff;
}
footer .pr {
  display: block;
  font-size: 80%;
}

#new dl {
  padding-left: 20px;
  margin-bottom: 15px;
}

#new dt {
  float: left;
  width: 9em;
  letter-spacing: 0.1em;
}
/*記事設定*/
#new dd {
  padding-left: 9em;
}

.ta1 caption {
  border: 1px solid #4f76ff;
  border-bottom: none;
  text-align: center;
  background: #4f76ff;
  color: #fff;
  font-weight: bold;
  padding: 10px;
}

.ta1 th.tamidashi {
  width: auto;
  text-align: center;
  background: #4f76ff;
  color: #fff;
}

.ta1 {
  table-layout: fixed;
  width: 100%;
  margin: 0 auto 20px;
  background: #fff;
}
.ta1,
.ta1 td,
.ta1 th {
  word-break: break-all;
  border: 1px solid #4f76ff;
  padding: 10px;
}

.ta1 th {
  text-align: center;
  width: 30%;
}

.scroll-show {
  display: block;
}
.scroll a {
  display: block;
  text-decoration: none;
  text-align: center;
  width: 50px;
  line-height: 50px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid #fff;
}

.scroll a:hover {
  background: rgba(0, 0, 0, 0.8);
}

.newicon {
  background: #f00;
  color: #fff;
  font-size: 70%;
  line-height: 1.5;
  padding: 2px 5px;
  border-radius: 2px;
  margin: 0px 5px;
  vertical-align: text-top;
}

ul.disc {
  list-style: disc;
  padding: 0 20px 20px 55px;
}
ol {
  padding: 0 20px 20px 42px;
}

.look {
  background: #fff;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 4px;
}
.mb15,
.mb1em {
  margin-bottom: 15px;
}
.clear {
  clear: both;
}
ul.disc {
  padding: 0em 25px 15px 40px;
  list-style: disc;
}
.color1,
.color1 a {
  color: #ff4765;
}
.pr {
  font-size: 10px;
}
.wl {
  width: 96%;
}
.ws {
  width: 50%;
}
.c {
  text-align: center;
}
.r {
  text-align: right;
}
.l {
  text-align: left;
}
.big1 {
  font-size: 40px;
  letter-spacing: 0.2em;
}
.mini1 {
  font-size: 11px;
  display: inline-block;
  line-height: 1.5;
}
.sh {
  display: none;
}

@media screen and (max-width: 800px) {
  header #logo img {
    width: 30%;
    top: 25%;
  }

  #menubar ul {
    justify-content: flex-start; /* スマホ表示では左端からアイテムを並べます */
  }

  #menubar li {
    flex-basis: 100%; /* スマホ表示では各アイテムをフル幅で表示し、左端から開始します */
  }
}

@media screen and (max-width: 480px) {
  header #logo img {
    width: 50%;
    left: 25%;
  }

  #container {
    font-size: 12px;
    font-size: 2.93vw;
  }

  #contents h2 {
    font-size: 16px;
  }

  #contents h3 {
    font-size: 14px;
  }

  .list h4 {
    font-size: 16px;
  }

  ul.disc {
    padding: 0 0px 20px 35px;
  }
  ol {
    padding: 0 0px 20px 35px;
  }
  .ws {
    width: 96%;
  }
  .big1 {
    font-size: 22px;
    letter-spacing: normal;
  }
}
